import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { db } from "../firebase"; // Import the Firestore instance
import { collection, query, where, getDocs } from "firebase/firestore"; // Firestore functions
import "../Styles/OrderHistory.css"; // Ensure you have CSS styles in place

const OrderHistory = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const userEmail = localStorage.getItem("userEmail"); // Retrieve email from local storage

  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (!userEmail) {
        console.error("No email found in local storage");
        return; // Exit if no email is found
      }

      try {
        const subscriptionsRef = collection(db, "subscriptions"); // Change to your collection name
        const q = query(
          subscriptionsRef,
          where("billing.email", "==", userEmail) // Use the retrieved email
        );

        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));

        setSubscriptionData(data);
      } catch (error) {
        console.error("Error fetching subscriptions: ", error);
      }
    };

    fetchSubscriptions();
  }, [userEmail]);

  // Main columns for the order list
  const columns = [
    {
      title: "Order ID", // Add a new column for Order ID
      dataIndex: "key", // Use the key which is the order ID
      key: "key",
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method_title",
      key: "payment_method",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (amount) => `₹${amount}`,
    },
  ];

  // Expanded row to show delivery dates and items
  const expandedRowRender = (record) => {
    const deliveryColumns = [
      {
        title: "Delivery Date",
        dataIndex: "deliveryDates",
        key: "deliveryDates",
        render: (dates) => dates.map((date) => <Tag key={date}>{date}</Tag>),
      },
    ];

    const itemColumns = [
      {
        title: "Item Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => `₹${price}`,
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
      },
    ];

    return (
      <div>
        <h4>Order Items</h4>
        <Table
          columns={itemColumns}
          dataSource={record.line_items}
          pagination={false}
          rowKey={(item) => item.product_id}
          scroll={{ x: "max-content" }} // Enable horizontal scrolling for nested table
        />
        <h4>Delivery Dates</h4>
        <Table
          columns={deliveryColumns}
          dataSource={[{ deliveryDates: record.deliveryDates }]}
          pagination={false}
          rowKey="deliveryDates"
          scroll={{ x: "max-content" }} // Enable horizontal scrolling for delivery dates table
        />
      </div>
    );
  };

  return (
    <div className="order-history-container">
      <h2>Subscription Order History</h2>
      <Table
        columns={columns}
        dataSource={subscriptionData}
        expandable={{
          expandedRowRender,
        }}
        rowKey={(record) => record.key}
        scroll={{ x: 800 }} // Enable horizontal scrolling for main table, set a fixed width or max-content
      />
    </div>
  );
};

export default OrderHistory;
