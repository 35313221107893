// import React, { useContext } from "react";
// import { FaShoppingCart, FaUser } from "react-icons/fa"; // Import FaUser icon
// import "../Styles/Header.css"; // Assuming you have a CSS file for styling
// import { ProductContext } from "../Context/cartContext"; // Import the ProductContext
// import { useNavigate } from "react-router-dom"; // Import useNavigate hook
// import Logo from "../Images/logo.webp";

// function Header() {
//   const navigate = useNavigate(); // Get the navigate function
//   const { selectedProducts } = useContext(ProductContext); // Get selectedProducts from context

//   const navigateToCart = () => {
//     navigate("/cart"); // Navigate to the cart page
//   };

//   const navigateToAccount = () => {
//     navigate("/account"); // Navigate to the account/profile page
//   };

//   const redirectToHome = () => {
//     window.location.href = "https://vayalvandi.com/";
//   };

//   return (
//     <div className="header">
//       <div className="header-logo-home">
//         <div className="header-logo" onClick={() => navigate("/")}>
//           <img src={Logo} alt="Logo" />
//         </div>
//         <a className="header-home" onClick={redirectToHome}>
//           Home
//         </a>
//       </div>
//       <div className="header-title">vayalVandii Subscription</div>
//       <div className="header-icons">
//         <div className="header-cart" onClick={navigateToCart}>
//           <FaShoppingCart style={{ color: "white" }} />
//           <span className="cart-count">{selectedProducts.length}</span>
//         </div>
//         <div className="header-account" onClick={navigateToAccount}>
//           <FaUser style={{ color: "white", marginLeft: "20px" }} />{" "}
//           {/* Account Icon */}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Header;

import React, { useContext } from "react";
import { FaShoppingCart, FaUser } from "react-icons/fa"; // Import FaUser icon
import "../Styles/Header.css"; // Assuming you have a CSS file for styling
import { ProductContext } from "../Context/cartContext"; // Import the ProductContext
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Logo from "../Images/logo.webp";

function Header() {
  const navigate = useNavigate(); // Get the navigate function
  const { selectedProducts } = useContext(ProductContext); // Get selectedProducts from context

  const navigateToCart = () => {
    navigate("/cart"); // Navigate to the cart page
  };

  const navigateToAccount = () => {
    const loginStatus = localStorage.getItem("loginStatus"); // Retrieve login status from local storage
    if (loginStatus === "true") {
      navigate("/order-history"); // Navigate to order history if logged in
    } else {
      navigate("/login"); // Navigate to login page if not logged in
    }
  };

  const redirectToHome = () => {
    window.location.href = "https://vayalvandi.com/";
  };

  return (
    <div className="header">
      <div className="header-logo-home">
        <div className="header-logo" onClick={() => navigate("/")}>
          <img src={Logo} alt="Logo" />
        </div>
        <a className="header-home" onClick={redirectToHome}>
          Home
        </a>
      </div>
      <div className="header-title">vayalVandii Subscription</div>
      <div className="header-icons">
        <div className="header-cart" onClick={navigateToCart}>
          <FaShoppingCart style={{ color: "white" }} />
          <span className="cart-count">{selectedProducts.length}</span>
        </div>
        <div className="header-account" onClick={navigateToAccount}>
          <FaUser style={{ color: "white", marginLeft: "20px" }} />{" "}
          {/* Account Icon */}
        </div>
      </div>
    </div>
  );
}

export default Header;
