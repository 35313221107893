import logo from "./logo.svg";
import "./App.css";
import Subscrption from "./Pages/subscription";
import Header from "./Pages/Header";
import CartPage from "./Pages/CartPage";
import OrderHistory from "./Pages/OrderHistory";

import Login from "./Pages/Login";
import { ProductProvider } from "./Context/cartContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FormProvider } from "./Context/FormContext";

function App() {
  return (
    <Router>
      <FormProvider>
        <ProductProvider>
          <div className="App">
            <Header />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Subscrption />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/order-history" element={<OrderHistory />} />
            </Routes>
          </div>
        </ProductProvider>
      </FormProvider>
    </Router>
  );
}

export default App;
