import React, { useState } from "react";
import { Form, Input, Button, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase"; // Import the Firestore instance
import { collection, getDocs, query, where } from "firebase/firestore"; // Firestore functions
import "../Styles/Login.css"; // Custom CSS file for additional styling
import Logo from "../Images/logo.webp"; // Assuming you have a logo

function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true); // Set loading state
    const { username, password } = values;

    try {
      // Create a query to find the user in the subscriptionUser collection
      const q = query(
        collection(db, "subscriptionUser"),
        where("email", "==", username),
        where("phone", "==", password) // Change this if you hash passwords
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // User found, save email and login status in local storage
        const userData = querySnapshot.docs[0].data();
        localStorage.setItem("loginStatus", "true"); // Set login status
        localStorage.setItem("userEmail", userData.email); // Save user's email

        // Navigate to layout/dashboard
        navigate("/order-history");
      } else {
        alert("Invalid credentials, please try again!");
      }
    } catch (error) {
      console.error("Error fetching user: ", error);
      alert("An error occurred while logging in. Please try again.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="login-container">
      {/* <div className="login-header">
        <img src={Logo} alt="Logo" className="login-logo" />
      </div> */}
      <Card className="login-card">
        <Form
          name="loginForm"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Enter Phone Number" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="login-button"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default Login;
